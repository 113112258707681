import React from 'react';

import reactStringReplace from 'react-string-replace';
import * as reactNl2br from 'react-nl2br';
import { v4 as uuidv4 } from 'uuid';
export * from '@helpers/utilities/constant';
export * from '@helpers/utilities/format';
export * from '@helpers/utilities/form';
export * from '@helpers/utilities/search';
export * from '@helpers/utilities/storage';

/**
 * 同期スリープ処理
 * @param {number} time
 */
export const sleep = (time) => {
  //
  // 引数に指定された秒数、空タイムアウト処理をasyncで実行する処理
  // → 呼び出し元でawait実行すると同期スリープ処理として利用できる
  //
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, time);
  });
};

/**
 * 指定文字列がJSON形式か？検証する処理
 * @param {string} value
 * @returns
 */
export const isValidJson = (value) => {
  try {
    // 引数の文字列が設定されていない場合は処理終了
    if (!value) return false;
    // JSONパースを実行して、成功すれば検証合格
    JSON.parse(value);
    return true;
  } catch (e) {
    return false;
  }
};

export const getPageTitle = (list, location) => {
  if (
    !list ||
    !list.props ||
    !list.props.children ||
    !Array.isArray(list.props.children)
  ) {
    return '';
  }

  const item = list.props.children.find((item) => {
    if (!item || !item.props || !item.props.path) return false;
    return item.props.path == location.pathname;
  });

  return item && item.props && item.props.title ? item.props.title : '';
};

export const numWithDelimiter = (number) => {
  return String(number).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
};

export const nl2br = (msg) => {
  // null, 空文字の場合は処理終了
  if (!msg) return <></>;
  return reactNl2br(msg).map((item, index) => {
    // <></> は key を設定できないので、<React.Fragment /> を使う
    return <React.Fragment key={index}>{item}</React.Fragment>;
  });
};

export const nl2brAndAnchorLink = (msg) => {
  // null, 空文字の場合は処理終了
  if (!msg) return <></>;
  // nlbrは自作のものではなく、reactNl2brを使用
  return reactStringReplace(reactNl2br(msg), /(https?:\/\/\S+)/g, (match) => {
    return (
      <a key={uuidv4()} href={match} target="_blank" rel="noreferrer">
        {match}
      </a>
    );
  });
};

export const getGoogleTagManagerScriptTagComponent = () => {
  //
  // gtag埋め込み済みの場合は処理終了
  // * 使用するLayoutが変更された場合、gtagが重複設置されるのを防ぐ
  //
  if (window?.google_tag_manager) return;

  // 使用タグIDを設定
  const tagId =
    process?.env?.REACT_APP_ENV == 'production' ? 'GTM-KNT2MBD' : 'GTM-MHFG5JH';

  // gtag出力
  return (
    <script type="text/javascript">
      {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer', '${tagId}');`}
    </script>
  );
};

export const getGoogleTagManagerNoScriptTagDom = () => {
  // noscriptタグ用のIDを作成
  const id = 'idx-noscript-googletagmanager';

  // noscriptタグ用のIDを作成
  const tagId =
    process?.env?.REACT_APP_ENV == 'production' ? 'GTM-KNT2MBD' : 'GTM-MHFG5JH';

  // 指定IDでnoscriptタグが存在する場合は処理終了
  if (document.getElementById(id)) return null;

  // noscriptタグを作成
  let noscript = document.createElement('noscript');
  noscript.id = id;

  // iframeタグを作成
  let iframe = document.createElement('iframe');
  iframe.src = `https://www.googletagmanager.com/ns.html?id=${tagId}`;
  iframe.height = 0;
  iframe.width = 0;
  iframe.style = 'display:none;visibility:hidden';

  // noscriptタグにiframeタグを追加
  noscript.prepend(iframe);
  return noscript;
};

export const isFullWidthPageContent = (pathname) => {
  // 横幅100%表示対象コンテンツ: ダッシュボード
  if (pathname.startsWith('/home')) return true;

  // 横幅100%表示対象コンテンツ: 検索画面
  if (pathname.startsWith('/pricing')) return true;

  // 横幅100%表示対象コンテンツ: 検索画面
  if (pathname.startsWith('/search/projects')) return false;
  if (
    pathname.startsWith('/search/companies') ||
    pathname.startsWith('/search/orgs')
  )
    return false;
  if (
    pathname.startsWith('/search/users') ||
    pathname.startsWith('/search/ids')
  )
    return false;

  if (pathname.startsWith('/search/deals')) return false;

  if (pathname.startsWith('/search')) return true;

  // 横幅100%表示対象コンテンツ: 希望プロジェクト
  if (pathname.startsWith('/matching')) return true;

  // 横幅100%表示対象コンテンツ: お気に入り管理
  if (pathname.startsWith('/favorites')) return true;

  // 横幅100%表示対象コンテンツ: チャット
  if (pathname.startsWith('/chats')) return true;

  // 横幅100%表示対象コンテンツ: 繋がり
  if (pathname.startsWith('/connections') || pathname.startsWith('/networks'))
    return true;

  // 横幅100%表示対象コンテンツ: 通知
  if (pathname.startsWith('/notifications')) return true;

  // 横幅100%表示対象コンテンツ: 会社メンバーの設定
  if (pathname.match(/^\/(companies|orgs)\/[0-9a-zA-Z]{1,}\/members/))
    return true;

  // 横幅100%表示対象コンテンツ: 会社詳細 > 会社メンバー
  if (pathname.match(/^\/(companies|orgs)\/[0-9a-zA-Z]{1,}\/joined-members/))
    return true;

  // 横幅100%表示対象コンテンツ: 会社管理一覧
  if (
    (pathname.startsWith('/companies') &&
      !pathname.match(/^\/companies\/[0-9a-zA-Z]{1,}/)) ||
    (pathname.startsWith('/orgs') &&
      !pathname.match(/^\/orgs\/[0-9a-zA-Z]{1,}/))
  )
    return true;

  // 横幅100%表示対象コンテンツ: プロジェクト管理一覧
  if (
    pathname.startsWith('/projects') &&
    !pathname.match(/^\/projects\/[0-9a-zA-Z]{1,}/)
  ) {
    return true;
  }

  // 横幅100%表示対象コンテンツ: プロジェクト採用管理一覧
  if (pathname.match(/^\/projects\/[0-9a-zA-Z]{1,}\/recruit/)) {
    return true;
  }

  if (pathname.startsWith('/posts')) {
    return true;
  }

  if (pathname.startsWith('/post-tags')) {
    return true;
  }

  // 横幅100%表示対象コンテンツ: Public pots
  if (pathname.startsWith('/public-posts')) return false;

  // 横幅固定表示対象コンテンツ: アカウント設定
  if (pathname.startsWith('/settings')) return false;

  return false;
};

export const isPublicSharingPageContent = (pathname) => {
  // 各種作成ページは、公開パスから除外
  if (pathname.match(/^\/(companies|orgs)\/create\/?$/)) return false;
  if (pathname.match(/^\/(projects)\/create\/?$/)) return false;
  // 各種詳細ページは、公開パスとして判定
  if (pathname.match(/^\/(users|ids)\/[0-9a-zA-Z]{1,}\/?$/)) return true;
  if (pathname.match(/^\/(companies|orgs)\/[0-9a-zA-Z]{1,}\/?$/)) return true;
  if (pathname.match(/^\/(projects)\/[0-9a-zA-Z]{1,}\/?$/)) return true;
  return false;
};

export const isChatGroupMessagePageContent = (pathname) => {
  const regex = new RegExp('/chats/groups/[a-zA-Z0-9-]{1,}/messages');

  return pathname.match(regex) ? true : false;
};

export const hasSidebarPagePath = (pathname) => {
  if (pathname.startsWith('/settings')) return true;
  if (pathname.startsWith('/posts')) return true;
  if (pathname.startsWith('/post-tags')) return true;
  return false;
};

export const getParentMenuItemPath = (pathname) => {
  // 他のメニューパスと被らないパス文字列をもつメニュー
  if (pathname.startsWith('/home')) return '/home';
  if (pathname.startsWith('/favorites')) return '/favorites';
  if (pathname.startsWith('/networks')) return '/networks';

  // ------------------------------------------
  // 検索系
  // ------------------------------------------
  if (
    pathname.startsWith('/search/companies') ||
    pathname.startsWith('/search/orgs')
  )
    return '/search/orgs';
  if (
    pathname.startsWith('/search/users') ||
    pathname.startsWith('/search/ids')
  )
    return '/search/ids';
  if (pathname.startsWith('/search/projects')) return '/search/projects';

  // ------------------------------------------
  // 新規作成系: 詳細系ページのパスと文字列が被るので先にマッチさせる
  // ------------------------------------------
  if (
    pathname.startsWith('/companies/create') ||
    pathname.startsWith('/orgs/create')
  )
    return '/orgs';
  if (pathname.startsWith('/projects/create')) return '/projects';

  // ------------------------------------------
  // 詳細系
  // ------------------------------------------/
  if (pathname.match(/^\/(companies|orgs)\/[0-9a-zA-Z]{1,}$/))
    return '/search/orgs';
  if (pathname.match(/^\/(users|ids)\/[0-9a-zA-Z]{1,}$/)) return '/search/ids';
  if (pathname.match(/^\/projects\/[0-9a-zA-Z]{1,}$/))
    return '/search/projects';

  // ------------------------------------------
  // 管理系
  // ------------------------------------------
  if (pathname.startsWith('/orgs')) return '/"orgs';
  // if (pathname.startsWith('/users')) return '/users'
  if (pathname.startsWith('/projects')) return '/projects';

  return null;
};

export const isUrl = (url) => {
  if (!url) return null;
  try {
    new URL(url);
    return true;
  } catch (e) {
    return false;
  }
};

export const parseUrl = (url) => {
  if (!url) return null;
  try {
    return new URL(url);
  } catch (e) {
    return null;
  }
};

export const getSearchType = (pathname) => {
  // 検索クエリ取得
  // const search = window?.location?.search ? String(window?.location?.search) : '';

  // ページタイプ: プロジェクト系
  if (pathname.startsWith('/search/projects')) return 'project';
  if (pathname.startsWith('/projects')) return 'project';
  if (pathname.match(/^\/projects\/[0-9a-zA-Z]{1,}$/)) return 'project';

  // ページタイプ: ビジネスページ系
  if (
    pathname.startsWith('/search/companies') ||
    pathname.startsWith('/search/orgs')
  )
    return 'company';
  if (pathname.startsWith('/companies') || pathname.startsWith('/orgs'))
    return 'company';
  if (pathname.match(/^\/(companies|orgs)\/[0-9a-zA-Z]{1,}$/)) return 'company';

  // ページタイプ: ユーザー系
  if (
    pathname.startsWith('/search/users') ||
    pathname.startsWith('/search/ids')
  )
    return 'user';
  if (pathname.startsWith('/users') || pathname.startsWith('/ids'))
    return 'user';
  if (pathname.match(/^\/(users|ids)\/[0-9a-zA-Z]{1,}$/)) {
    return 'user';
  }

  // 投稿系のページは、どうするか？考える
  // if (
  //   pathname.startsWith('/posts') &&
  //   String(search) &&
  //   String(search).indexOf('companyId=')
  // )
  //   return 'company';

  return 'project';
};

export const getNextRedirectUrl = (url, urls) => {
  // urls配列にデータが存在しない場合は、nullを返却
  if (!urls || !Array.isArray(urls) || urls.length < 1) return null;

  // 現在のURLをパース
  const parseUrl = url ? new URL(url) : null;

  // 現在のURLをパース
  if (!parseUrl?.origin) return null;

  // 現在閲覧中のURLのindex番号を収録
  const currentIndex = urls.indexOf(parseUrl?.origin);

  // 現在閲覧中のURLがリストに存在しない場合は、0番目を返却
  if (currentIndex == -1) return null;

  // 次のURLのindex番号を作成
  const nextUrlIndex = currentIndex + 1;

  // 次のリダイレクト先が存在しない場合は、nullを返却
  if (!urls?.[nextUrlIndex]) return null;

  // 次のリダイレクトURLを返却
  return `${urls?.[nextUrlIndex]}`;
};

export const getAppDomain = (url) => {
  if (!url) return 'pjdb.cc';

  const parseUrl = new URL(url);

  if (parseUrl?.host && parseUrl?.host.indexOf('pjdb.io') != -1)
    return 'pjdb.io';

  return 'pjdb.cc';
};
