import { AuthContext } from '@contexts/authContext';
import { lazy, useContext } from 'react';
import { localstorageKeys } from '@constants/other';
import { Route as BaseRoute, Redirect, useLocation } from 'react-router-dom';
import * as utilities from '@helpers/utilities';

// handle auth and authorization
const Route = ({ component: Component, ...rest }) => {
  const { authState, loading } = useContext(AuthContext);
  const { state: locState = false } = useLocation();

  // ------------------------------------------
  // ユーザー認証状態別の認証ステータスフラグ値の変化
  // logout (ログアウト状態) --> isSignUp: false, isSignIn: false, isRegistered: false, isInActive: false or true
  // signup (ログアウト状態, アカウント登録済みみ状態) --> isSignUp: true, isSignIn: false, isRegistered: false, isInActive: false
  // signin (ログイン状態, プロフィール未登録) --> isSignUp: true, isSignIn: true, isRegistered: false, isInActive: false
  // login  (ログイン状態, プロフィール登録済み状態) --> isSignUp: true, isSignIn: true, isRegistered: true, isInActive: false
  // ------------------------------------------
  return (
    <BaseRoute
      {...rest}
      render={(props) => {
        const { pathname } = props.location;
        const isAccountPath = pathname.startsWith('/account') ? true : false;
        const isCallback = pathname.startsWith('/idpcallback');

        // ログインしていない状態でも閲覧可能なパスリスト
        const staticContentPaths = [
          '/pricing',
          '/contact',
          '/guideline',
          '/privacy-policy',
          '/terms',
          '/trade',
          '/error',
          '/sync',
        ];

        const publicPagePaths = ['/public-posts'];

        // ログインしていない状態でも閲覧可能なURLにアクセスしているか判定
        const isStaticContentPath =
          staticContentPaths.filter((path) => {
            return location.pathname.startsWith(path);
          }).length > 0
            ? true
            : false;

        // 閲覧中ページが公開ページのパスか？
        const isPublicPagePath =
          publicPagePaths.filter((path) => {
            return location.pathname.startsWith(path);
          }).length > 0;

        // 閲覧中ページが外部公開ページのパスか？
        const isPublicSharingPagePath =
          utilities.isPublicSharingPageContent(pathname);

        // ストレージ同期の為のリダイレクトフラグが設定されているか？
        const syncRedirect =
          localStorage.getItem('syncRedirect') &&
          (parseInt(localStorage.getItem('syncRedirect')) === 1 ||
            parseInt(localStorage.getItem('syncRedirect')) === 2)
            ? parseInt(localStorage.getItem('syncRedirect'))
            : 0;

        // 同期URLリストを取得
        const syncRedirectUrls = process.env?.REACT_APP_SYNC_URL_LIST
          ? String(process.env?.REACT_APP_SYNC_URL_LIST)
              .split(',')
              .map((item) => {
                // trim space
                return item.split(/^\s+|\s+$/g).join('');
              })
          : [];

        // ローカルストレージデータ取得
        const storageData = utilities.getLocalStorageItem(
          localstorageKeys.default
        );

        if (loading) {
          return <></>;
        } else if (syncRedirect != 0 && syncRedirectUrls.length > 1) {
          // make local storage base64 data.
          // cloud frontへリクエストURLとして設定できるURL文字数は最大8192文字
          // 全てのストレージデータを同期すると、base64文字列にした時、超過する可能性があるため、認証データだけ抽出
          const base64Data = utilities.getStorageAuthorizedDataAsBase64();

          // make callback url
          const callbackUrl = encodeURIComponent(
            `${process.env?.REACT_APP_URL}`
          );

          // get next redirect url
          const nextRedirectUrl = utilities.getNextRedirectUrl(
            window.location?.href,
            syncRedirectUrls
          );

          // ログイン状態の同期フラグに無効に設定
          localStorage.setItem('syncRedirect', 0);

          // is next redirect url ?
          if (!nextRedirectUrl) {
            // リダイレクト先が存在しない場合、現在のURLにリダイレクト
            return (
              <Redirect
                to={{
                  pathname: pathname,
                  state: {
                    from: locState || isAccountPath ? '/' : props.location,
                  },
                }}
              />
            );
          }

          // 次のリダイレクト先が存在する場合は、URLの完全置き換え（リフレッシュ && リダイレクト）
          // タイミングによって、リダイレクトが実行されない場合があるので、setIntervalで実行されるまで繰り返す
          const redirectTimer = setInterval(() => {
            window.location.href =
              syncRedirect == 1
                ? `${nextRedirectUrl}/sync?data=${base64Data}&callback=${callbackUrl}`
                : `${nextRedirectUrl}/sync?data=${Buffer.from(
                    JSON.stringify({})
                  ).toString('base64')}&callback=${callbackUrl}`;
            clearInterval(redirectTimer);
          }, 300);

          return <></>;
        } else if (
          !isStaticContentPath &&
          !isPublicPagePath &&
          !isPublicSharingPagePath
        ) {
          // アカウントの無効フラグが設定されている場合は、ログアウト画面以外はリダイレクト
          if (authState.isInActive && pathname !== '/account/logout') {
            return (
              <Redirect
                to={{
                  pathname: '/error/user-inactive',
                  state: {
                    from: locState || isAccountPath ? '/' : props.location,
                  },
                }}
              />
            );
          } else if (
            !authState.isSignUp &&
            !authState.isSignIn &&
            !authState.isRegisteredlogin &&
            pathname !== '/account/login' &&
            pathname !== '/account/signup' &&
            pathname !== '/account/forget-password' &&
            pathname !== '/account/reset-password'
            // pathname !== '/account/reset-password' &&
            // pathname !== '/account/verify'
          ) {
            // not logged in so redirect to login page with the return url
            return (
              <Redirect
                to={{
                  pathname: '/account/login',
                  state: {
                    from: locState || isAccountPath ? '/' : props.location,
                  },
                }}
              />
            );
          } else if (
            authState.isSignUp &&
            !authState.isSignIn &&
            !authState.isRegisteredlogin &&
            pathname !== '/account/verify' &&
            pathname !== '/account/logout'
          ) {
            return (
              <Redirect
                to={{
                  pathname: '/account/verify',
                  state: {
                    from: locState || isAccountPath ? '/' : props.location,
                  },
                }}
              />
            );
          } else if (
            authState.isSignUp &&
            authState.isSignIn &&
            !authState.isRegistered &&
            pathname !== '/account/register' &&
            pathname !== '/account/idpcallback' &&
            pathname !== '/account/logout'
          ) {
            // Routerでidpcallback(ソーシャルログイン)時のURLも自動リダイレクトすると画面がガチャガチャするのが見えてしまうので、リダイレクトしない
            return (
              <Redirect
                to={{
                  pathname: '/account/register',
                  state: {
                    from: locState || isAccountPath ? '/' : props.location,
                  },
                }}
              />
            );
          }
          if (
            authState.isSignUp &&
            authState.isSignIn &&
            authState.isRegistered &&
            (isAccountPath || isCallback) &&
            pathname !== '/account/logout'
          ) {
            return (
              <Redirect
                to={{ pathname: locState ? locState.from.pathname : '/' }}
              />
            );
          }
          // ログイン状態 &&
          // 自動遷移先が存在する場合
          if (
            authState.isSignUp &&
            authState.isSignIn &&
            authState.isRegistered &&
            storageData.redirectUrlAfterLogin
          ) {
            // 自動遷移先をクリア
            const redirectUrlAfterLogin = storageData.redirectUrlAfterLogin;
            storageData.redirectUrlAfterLogin = null;
            utilities.setLocalStorageItem(
              localstorageKeys.default,
              storageData
            );
            return (
              <Redirect
                to={{
                  pathname: redirectUrlAfterLogin,
                }}
              />
            );
          }
        }

        // もし、旧リンクが残っており、旧URLでアクセスされたら、新URLにリダイレクト
        // Googleは、jsのリダイレクトにも対応しているとの事だが、基本的に発生させないようにする
        // ※ ユーザーが使用できない状況を避けるための保険
        // v2で完全に削除予定
        if (
          pathname.indexOf('/search/users') != -1 ||
          pathname.match(/^\/users\/[0-9a-zA-Z]{1,}$/)
        ) {
          return (
            <Redirect
              to={{
                pathname: `${location?.pathname.replace('/users', '/ids')}`,
                search: location?.search ?? '',
              }}
            />
          );
        }

        if (
          pathname.indexOf('/search/companies') != -1 ||
          pathname.match(/^\/companies\/[0-9a-zA-Z]{1,}$/)
        ) {
          return (
            <Redirect
              to={{
                pathname: `${location?.pathname.replace(
                  '/companies',
                  '/orgs'
                )}`,
                search: location?.search ?? '',
              }}
            />
          );
        }

        // authorised so return component
        return <Component {...rest} {...props} />;
      }}
    />
  );
};

// deal機能を有効にするか？否か？
const isEnabledFeatures = {
  deal:
    parseInt(process.env?.REACT_APP_IS_ENABLE_FEATURE_DEAL) == 1 ? true : false,
};

// root routes
const rootRoute = {
  path: '/',
  exact: true,
  component: () => <Redirect to="/home" />,
  route: Route,
};

const dashboardRoutes = [
  {
    path: '/home',
    name: 'ホーム',
    icon: 'mdi mdi-view-dashboard',
    isDashboardRoutes: true,
    header: 'Navigation',
    // component: lazy(() => import('@pages/dashboards/Project')),
    component: lazy(() => import('@pages/dashboards/index')),
    route: Route,
  },
];

// 各種機能
const featureAppRoutes = [
  {
    path: '/favorites',
    name: 'お気に入り',
    route: Route,
    icon: 'mdi mdi-bookmark',
    isFeatureAppRoutes: true,
    isEnabled: true,
    component: lazy(() => import('@pages/favorites')),
  },
  {
    path: '/search/projects',
    name: 'プロジェクト検索',
    route: Route,
    icon: 'mdi mdi-database-search',
    isFeatureAppRoutes: true,
    isEnabled: true,
    component: lazy(() => import('@pages/search/Project')),
  },
  {
    path: '/search/ids',
    name: 'ユーザー検索',
    route: Route,
    icon: 'mdi mdi-database-search',
    isFeatureAppRoutes: true,
    isEnabled: true,
    component: lazy(() => import('@pages/search/User')),
  },
  {
    path: '/search/orgs',
    name: '会社検索',
    route: Route,
    icon: 'mdi mdi-database-search',
    isFeatureAppRoutes: true,
    isEnabled: true,
    component: lazy(() => import('@pages/search/Company')),
  },
  {
    path: '/search/deals',
    name: 'Deals',
    route: Route,
    icon: 'mdi mdi-database-search',
    isFeatureAppRoutes: true,
    isEnabled: isEnabledFeatures?.deal ?? false,
    component: lazy(() => import('@pages/search/Deal')),
  },
  {
    path: '/projects',
    name: 'プロジェクト管理',
    route: Route,
    exact: true,
    icon: 'mdi mdi-database',
    isFeatureAppRoutes: true,
    isEnabled: true,
    component: lazy(() => import('@pages/projects')),
  },
  {
    path: '/orgs',
    name: 'ビジネスページ',
    route: Route,
    icon: 'mdi mdi-office-building',
    isFeatureAppRoutes: true,
    isEnabled: true,
    component: lazy(() => import('@pages/companies')),
  },
]
  .filter((item) => {
    return item?.isEnabled ?? false;
  })
  .map((item) => item);

// 各種機能
const featureHideAppRoutes = [
  // ---------------------------------------------------
  // マッチング系ページ
  // ---------------------------------------------------
  {
    path: '/matching/projects',
    name: 'プロジェクト作成',
    route: Route,
    isEnabled: true,
    component: lazy(() => import('@pages/matching/Project')),
  },

  // ---------------------------------------------------
  // プロジェクト管理系ページ
  // ---------------------------------------------------
  {
    path: '/projects/create',
    name: 'プロジェクト作成',
    route: Route,
    parentPath: '/projects',
    isEnabled: true,
    component: lazy(() => import('@pages/projects/Create')),
  },
  {
    path: '/projects/:id/settings',
    name: 'プロジェクト設定',
    route: Route,
    parentPath: '/projects',
    isEnabled: true,
    component: lazy(() => import('@pages/projects/Setting')),
  },
  {
    path: '/projects/:id/recruit',
    name: 'プロジェクト採用詳細',
    route: Route,
    parentPath: '/projects',
    isEnabled: true,
    component: lazy(() => import('@pages/projects/Recruit')),
  },

  // ---------------------------------------------------
  // 会社管理系ページ
  // ---------------------------------------------------
  {
    path: '/orgs/create',
    name: '会社作成',
    route: Route,
    parentPath: '/orgs',
    isEnabled: true,
    component: lazy(() => import('@pages/companies/Create')),
  },
  {
    path: '/orgs/:id/members',
    name: '会社メンバー設定',
    route: Route,
    icon: 'uil-store',
    isEnabled: true,
    component: lazy(() => import('@pages/companies/Member')),
  },
  {
    path: '/orgs/:id/settings',
    name: '会社設定',
    route: Route,
    icon: 'uil-store',
    isEnabled: true,
    component: lazy(() => import('@pages/companies/Setting')),
  },
  {
    path: '/orgs/:id/deals/:dealId/create',
    name: 'サービス（Deal）作成',
    route: Route,
    parentPath: '/orgs',
    isEnabled: isEnabledFeatures?.deal ?? false,
    component: lazy(() => import('@pages/companies/Deal/Create')),
  },
  {
    path: '/orgs/:id/deals/:dealId/settings',
    name: 'サービス（Deal）設定',
    route: Route,
    parentPath: '/orgs',
    isEnabled: isEnabledFeatures?.deal ?? false,
    component: lazy(() => import('@pages/companies/Deal/Setting')),
  },

  // ---------------------------------------------------
  // 商材（Deal）系ページ
  // ---------------------------------------------------
  {
    path: '/deals/:id/subscribe',
    name: 'Deal申し込み',
    route: Route,
    parentPath: '/deals/:id/',
    isEnabled: isEnabledFeatures?.deal ?? false,
    component: lazy(() => import('@pages/deals/Subscribe')),
  },
  {
    path: '/deals/:id',
    name: 'Deal詳細',
    route: Route,
    parentPath: '/deals/:id/',
    isEnabled: isEnabledFeatures?.deal ?? false,
    component: lazy(() => import('@pages/deals/Detail')),
  },

  // ---------------------------------------------------
  // 各種詳細系ページ
  // ---------------------------------------------------
  {
    path: '/projects/:id',
    name: 'プロジェクト詳細',
    route: Route,
    parentPath: '/search/projects',
    isEnabled: true,
    component: lazy(() => import('@pages/projects/Detail')),
  },
  {
    path: '/orgs/:id/joined-members',
    name: '会社詳細',
    route: Route,
    parentPath: '/orgs/:id/',
    isEnabled: true,
    component: lazy(() => import('@pages/companies/Detail/Member')),
  },
  {
    path: '/search/companies',
    name: '会社検索',
    route: Route,
    icon: 'mdi mdi-database-search',
    isFeatureAppRoutes: true,
    isEnabled: true,
    component: lazy(() => import('@pages/search/Company')),
  },
  {
    path: '/companies/:id',
    name: '会社詳細',
    route: Route,
    parentPath: '/search/companies',
    isEnabled: true,
    component: lazy(() => import('@pages/companies/Detail')),
  },
  {
    path: '/orgs/:id',
    name: '会社詳細',
    route: Route,
    parentPath: '/search/orgs',
    isEnabled: true,
    component: lazy(() => import('@pages/companies/Detail')),
  },

  {
    path: '/search/users',
    name: 'ユーザー検索',
    route: Route,
    icon: 'mdi mdi-database-search',
    isFeatureAppRoutes: true,
    isEnabled: true,
    component: lazy(() => import('@pages/search/User')),
  },
  {
    path: '/users/:id',
    name: 'ユーザー詳細',
    route: Route,
    parentPath: '/search/ids',
    isEnabled: true,
    component: lazy(() => import('@pages/users/Detail')),
  },
  {
    path: '/ids/:id',
    name: 'ユーザー詳細',
    route: Route,
    parentPath: '/search/ids',
    isEnabled: true,
    component: lazy(() => import('@pages/users/Detail')),
  },
  {
    path: '/connection/:id/members',
    name: 'Connection Members',
    route: Route,
    parentPath: '/search/ids',
    isEnabled: true,
    component: lazy(() => import('@pages/connections/Member/index')),
  },

  // ---------------------------------------------------
  // その他メニュー（サイドバーにはリンクがないメニュー
  // ---------------------------------------------------
  {
    path: '/networks',
    name: 'マイネットワーク',
    route: Route,
    icon: 'mdi mdi-link-variant',
    isFeatureAppRoutes: true,
    isEnabled: true,
    component: lazy(() => import('@pages/connections/index')),
  },
  {
    path: '/notifications',
    name: '通知',
    route: Route,
    isEnabled: true,
    component: lazy(() => import('@pages/notifications')),
  },
  {
    path: '/chats/groups/:id/messages',
    name: 'チャットメッセージ',
    route: Route,
    isEnabled: true,
    component: lazy(() => import('@pages/chats/Message')),
  },
  {
    path: '/chats/groups',
    name: 'チャットグループ',
    route: Route,
    isEnabled: true,
    component: lazy(() => import('@pages/chats/Group')),
  },
  {
    path: '/contracts/create',
    name: '契約書作成',
    route: Route,
    isEnabled: true,
    component: lazy(() => import('@pages/contracts/Create')),
  },
  {
    path: '/options/purchase',
    name: 'オプション購入',
    route: Route,
    isEnabled: true,
    component: lazy(() => import('@pages/options/Purchase')),
  },
  {
    path: '/options/checkout',
    name: 'オプション購入チェックアウト',
    route: Route,
    isEnabled: true,
    component: lazy(() => import('@pages/options/Checkout')),
  },
  {
    path: '/pricing',
    name: '料金プラン',
    route: Route,
    isEnabled: true,
    component: lazy(() => import('@pages/pricing')),
  },
  {
    path: '/invoices',
    name: '請求書',
    route: Route,
    isEnabled: true,
    component: lazy(() => import('@pages/invoices')),
  },
  {
    path: '/public-posts',
    name: 'Public Posts',
    icon: 'mdi mdi-post',
    isPostRoutes: true,
    isEnabled: true,
    component: lazy(() => import('@pages/posts/PublicList')),
    route: Route,
  },
  // {
  //   path: '/options/order',
  //   name: 'オプション購入',
  //   route: Route,
  //   component: lazy(() => import('@pages/options/Order')),
  // },
  // {
  //   path: '/web_meetings/create',
  //   name: 'webMTG作成',
  //   route: Route,
  //   component: lazy(() => import('@pages/webMeetings/Create')),
  // },
  // {
  //   path: '/contracts/:id/update',
  //   name: '契約書更新',
  //   route: Route,
  //   roles: ['Admin'],
  //   icon: 'uil-store',
  //   component: lazy(() => import('@pages/contracts/Edit')),
  // },
  // {
  //   path: '/web_meetings',
  //   name: 'webMTG管理',
  //   route: Route,
  //   icon: 'uil-meeting-board',
  //   component: lazy(() => import('@pages/webMeetings')),
  // },
  // {
  //   path: '/web_meetings/create',
  //   name: 'webMTGルーム作成',
  //   route: Route,
  //   icon: 'uil-briefcase',
  //   component: lazy(() => import('@pages/webMeetings/Order')),
  // },
  // {
  //   path: '/web_meetings/:hash/update',
  //   name: 'webMTGルーム更新',
  //   route: Route,
  //   icon: 'uil-briefcase',
  //   component: lazy(() => import('@pages/webMeetings/Edit')),
  // },
  // {
  //   path: '/contracts',
  //   name: '契約書管理',
  //   route: Route,
  //   // icon: 'uil-file-contract-dollar',
  //   icon: 'mdi mdi-folder-edit',
  //   component: lazy(() => import('@pages/contracts')),
  // },
  // {
  //   path: '/web_meetings',
  //   name: 'webMTG管理',
  //   route: Route,
  //   // icon: 'uil-calendar-alt',
  //   icon: 'mdi mdi-file-lock-outline',
  //   component: lazy(() => import('@pages/webMeetings')),
  // },
]
  .filter((item) => {
    return item?.isEnabled ?? false;
  })
  .map((item) => item);

// auth
const authRoutes = {
  path: '/account',
  name: 'Auth',
  children: [
    {
      // title: 'ログイン',
      path: '/account/login',
      name: 'Login',
      isEnabled: true,
      component: lazy(() => import('@pages/auth/SignIn')),
      route: Route,
    },
    {
      // title: 'アカウントの新規登録',
      path: '/account/signup',
      name: 'Signup',
      isEnabled: true,
      component: lazy(() => import('@pages/auth/SignUp')),
      route: Route,
    },
    {
      // title: 'アカウントの新規登録',
      path: '/account/verify',
      name: 'Verify mail for sign up',
      isEnabled: true,
      component: lazy(() => import('@pages/auth/SignUpVerify')),
      route: Route,
    },
    {
      path: '/account/logout',
      name: 'Logout',
      isEnabled: true,
      component: lazy(() => import('@pages/auth/LogOut')),
      route: Route,
    },
    {
      // title: 'アカウントプロフィールの登録',
      path: '/account/register',
      name: 'Register',
      isEnabled: true,
      component: lazy(() => import('@pages/auth/Register')),
      route: Route,
    },
    {
      // title: 'パスワードのリセット',
      path: '/account/forget-password',
      name: 'Forget Password',
      isEnabled: true,
      component: lazy(() => import('@pages/auth/ForgetPassword')),
      route: Route,
    },
    {
      // title: 'パスワードのリセット',
      path: '/account/reset-password',
      name: 'Reset Password',
      isEnabled: true,
      component: lazy(() => import('@pages/auth/ResetPassword')),
      route: Route,
    },
    {
      path: '/account/idpcallback',
      name: 'Callback',
      isEnabled: true,
      component: lazy(() => import('@pages/auth/Callback')),
      route: Route,
    },
  ]
    .filter((item) => {
      return item?.isEnabled ?? false;
    })
    .map((item) => item),
};

// 設定
const settingRoutes = [
  {
    path: '/settings/profile',
    name: 'アカウント設定',
    icon: 'mdi mdi-account-edit-outline',
    isEnabled: true,
    component: lazy(() => import('@pages/settings/Profile')),
    children: [
      {
        path: '/settings/profile',
        name: 'プロフィール情報',
        // icon: 'mdi mdi-account-edit-outline',
        component: lazy(() => import('@pages/settings/Profile')),
        route: Route,
      },
      {
        path: '/settings/work-experience',
        name: 'スキル/経歴',
        // icon: 'mdi mdi-file-key-outline',
        component: lazy(() => import('@pages/settings/WorkExperience')),
        route: Route,
      },
    ],
    route: Route,
  },
  {
    path: '/settings/project-condition',
    name: 'アクティビティ',
    icon: 'mdi mdi-file-document-box-check-outline',
    isEnabled: true,
    component: lazy(() => import('@pages/settings/ProjectCondition')),
    children: [
      {
        path: '/settings/project-condition',
        name: '希望プロジェクト',
        // icon: 'mdi mdi-file-document-box-check-outline',
        component: lazy(() => import('@pages/settings/ProjectCondition')),
        route: Route,
      },
      {
        path: '/settings/availability',
        name: 'アベイラビリティ',
        // icon: 'mdi mdi-file-document-box-check-outline',
        component: lazy(() => import('@pages/settings/Availability')),
        route: Route,
      },
    ],
    route: Route,
  },
  {
    path: '/settings/login',
    name: 'セキュリティ',
    icon: 'mdi mdi-security',
    isEnabled: true,
    component: lazy(() => import('@pages/settings/Login')),
    children: [
      {
        path: '/settings/login',
        name: 'ログイン設定',
        // icon: 'mdi mdi-security',
        component: lazy(() => import('@pages/settings/Login')),
        route: Route,
      },
      {
        path: '/settings/publish-profile',
        name: '公開設定',
        // icon: 'mdi mdi-publish',
        component: lazy(() => import('@pages/settings/PublishProfile')),
        route: Route,
      },
      {
        path: '/settings/notification',
        name: '通知設定',
        // icon: 'mdi mdi-bell-outline',
        component: lazy(() => import('@pages/settings/Notification')),
        route: Route,
      },
    ],
    route: Route,
  },
  {
    path: '/home',
    name: '戻る',
    icon: 'dripicons-return',
    header: 'Navigation',
    isEnabled: true,
    component: lazy(() => import('@pages/dashboards/index')),
    route: Route,
  },
]
  .filter((item) => {
    return item?.isEnabled ?? false;
  })
  .map((item) => item);

// 支払い
const billingRoutes = [
  // {
  //   path: '/billing',
  //   name: '請求とプラン',
  //   icon: 'mdi mdi-account-edit-outline',
  //   component: lazy(() => import('@pages/payments')),
  //   route: Route,
  // },
  // {
  //   path: '/billing/history',
  //   name: '履歴',
  //   icon: 'dripicons-return',
  //   header: 'Navigation',
  //   component: lazy(() => import('@pages/payments')),
  //   route: Route,
  // },
  // {
  //   path: '/dashboard',
  //   name: '戻る',
  //   icon: 'dripicons-return',
  //   header: 'Navigation',
  //   component: lazy(() => import('@pages/dashboards/index')),
  //   route: Route,
  // },
];

const postRoutes = [
  {
    path: '/posts',
    name: '投稿',
    icon: 'mdi mdi-post',
    isPostRoutes: true,
    isEnabled: true,
    component: lazy(() => import('@pages/posts/index')),
    route: Route,
  },
]
  .filter((item) => {
    return item?.isEnabled ?? false;
  })
  .map((item) => item);

const postTagRoutes = [
  {
    path: '/post-tags',
    name: '投稿タグ',
    icon: 'mdi mdi-tag',
    isPostRoutes: true,
    isEnabled: true,
    component: lazy(() => import('@pages/posts/Tag/index')),
    route: Route,
  },
]
  .filter((item) => {
    return item?.isEnabled ?? false;
  })
  .map((item) => item);

// リンク
const linkRoutes = [
  {
    // title: '利用規約',
    path: '/terms',
    name: 'terms',
    isEnabled: true,
    component: lazy(() => import('@pages/terms')),
    route: Route,
  },
  {
    // title: 'プライバシーポリシー',
    path: '/privacy-policy',
    name: 'Privacy Policy',
    isEnabled: true,
    component: lazy(() => import('@pages/privacyPolicy')),
    route: Route,
  },
  {
    // title: 'ガイドライン',
    path: '/guideline',
    name: 'guideline',
    isEnabled: true,
    component: lazy(() => import('@pages/guideline')),
    route: Route,
  },
  {
    // title: '特定商取引法に基づく表記',
    path: '/trade',
    name: 'Trade',
    isEnabled: true,
    component: lazy(() => import('@pages/trade')),
    route: Route,
  },
  {
    // title: '問い合わせ',
    path: '/contact',
    name: 'Contact',
    isEnabled: true,
    component: lazy(() => import('@pages/contact')),
    route: Route,
  },
  {
    // title: '同期',
    path: '/sync',
    name: 'Storage Sync',
    isEnabled: true,
    component: lazy(() => import('@pages/sync')),
    route: Route,
  },
]
  .filter((item) => {
    return item?.isEnabled ?? false;
  })
  .map((item) => item);

// エラー画面 (* 複数画面必要になるかもしれないので、専用配列として用意
const errorRoutes = [
  {
    path: '/error/user-inactive',
    name: '410',
    isEnabled: true,
    component: lazy(() => import('@pages/error/UserInActive')),
    route: Route,
  },
  {
    path: '/error',
    name: 'error',
    isEnabled: true,
    component: lazy(() => import('@pages/error/InternalServerError')),
    route: Route,
  },
  {
    // 他にマッチするページがない場合は、404にしたいのでパスはワイルドカード指定しています
    // ただ、意図的に404ページにリダイレクトしたい場合は、/404というパスでリダイレクトして下さい
    // path: '/404',
    path: '*',
    name: '404',
    isEnabled: true,
    component: lazy(() => import('@pages/error/PageNotFound')),
    route: Route,
  },
]
  .filter((item) => {
    return item?.isEnabled ?? false;
  })
  .map((item) => item);

// flatten the list of all nested routes
const flattenRoutes = (routes = []) =>
  routes.reduce((acc, crr) => {
    return typeof crr.children !== 'undefined'
      ? [...acc, crr, ...flattenRoutes(crr.children)]
      : [...acc, crr];
  }, []);

// 全てのルート
const allRoutes = [
  rootRoute,
  ...dashboardRoutes,
  ...featureHideAppRoutes,
  ...featureAppRoutes,
  authRoutes,
  ...settingRoutes,
  ...billingRoutes,
  ...postRoutes,
  ...postTagRoutes,
  ...linkRoutes,
  ...errorRoutes,
];

const allFlattenRoutes = flattenRoutes(allRoutes);

// メニューに表示したいルート設定
const navigationDisplayRoutes = [...dashboardRoutes, ...featureAppRoutes];
const navigationSettingDisplayRoutes = [...settingRoutes];
const navigationBillingDisplayRoutes = [...billingRoutes];
const navigationPostDisplayRoutes = [...postRoutes];
const navigationPostTagDisplayRoutes = [...postTagRoutes];

export {
  allFlattenRoutes,
  allRoutes,
  navigationBillingDisplayRoutes,
  navigationDisplayRoutes,
  navigationSettingDisplayRoutes,
  navigationPostDisplayRoutes,
  navigationPostTagDisplayRoutes,
};
