export const regexCode = '^[a-zA-Z0-9]{1,100}$';
export const regexAliasCode = '^(?!\\d+$)[a-zA-Z0-9](?:[a-zA-Z0-9-_]{0,98}[a-zA-Z0-9])?$';
export const regexEMail = '^[!-~]+@[!-~]+$';
export const regexFormatNumber = '^[0-9,]{1,}$';
// export const regexUserInviteCode = '^[a-zA-Z0-9]{1,100}$'
export const regexUserInviteCode = '^[a-zA-Z0-9]{1,}$';
export const regexKana = '^[ァ-ヶーｦ-ﾟ]{1,}$';
export const regexKanaWithAlphabet = '^[a-zA-Zァ-ヶーｦ-ﾟ]{1,}$';
export const regexPhoneNumber = '^[0-9+\\-]{1,}$';
// export const regexAccountPassword =
//   '^(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#%&_-])[a-zA-Z0-9!@#%&_-]{8,64}$'
export const regexAccountPassword =
  '^(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])[a-zA-Z0-9!@#%&_\\-]{8,64}$';
export const regexAccountPasswordEnableCharacter =
  '^[a-zA-Z0-9!@#%&_\\-]{8,64}$';
export const regexUrlAuthCode = '^(UI-)[0-9a-zA-Z]{1,}$';
export const regexZipCode = '^[0-9]{3}[\\-]{0,1}[0-9]{4}$';
export const regexZipCodeWithAbroad = '^(?![\\s\\-]*$)[0-9a-zA-Z\\-\\s]{1,12}$';
